import React from "react"
import { Flex, Box } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SelectedWorkLink from "../components/selectedWorkLink"
import anywhrRedesignCover from "../images/anywhr-redesign-cover.png"
import anywhrTMTCover from "../images/anywhr-tailor-made-trips-cover.png"
import anywhrBrandingRefreshCover from "../images/anywhr-branding-refresh-cover.png"
import anywhrLIPCover from "../images/anywhr-lip-cover.png"

const works = [
  {
    title: "Anywhr Redesign: Trip Customisation & Web App",
    subtitle:
      "Increasing users' confidence towards a new and unconventional travel experience",
    meta: "2019 · Research · UX · UI",
    path: "/2019/anywhr-web-app-redesign",
    cover: anywhrRedesignCover,
  },
  {
    title: "Introducing Tailor-Made Trips",
    subtitle: "Anywhr's new direction in trip planning",
    meta: "2019 · UX · UI",
    path: "/2019/anywhr-tailor-made-trips",
    cover: anywhrTMTCover,
  },
  {
    title: "Anywhr Brand Refresh: Sophisticated Fun",
    subtitle:
      "Updating Anywhr's visual identity to match their target audience",
    meta: "2020 · Branding · UI",
    path: "/2020/anywhr-brand-refresh",
    cover: anywhrBrandingRefreshCover,
  },
  {
    title: "Anywhr's Own Itinerary Planning Tool",
    subtitle: "Scaling & optimising trip tlanning for all kinds of trips",
    meta: "2020 · Research · UX · UI",
    path: "/2020/anywhr-lip",
    cover: anywhrLIPCover,
  },
]

const IndexPage = ({ ...props }) => (
  <Layout {...props}>
    <SEO title="Home" />
    <Flex justifyContent="center" p={[1, 2, 4]}>
      <Box width={[1, 5 / 6, 5 / 6]} px={[1, 2, 4]} mb={[5, 6, 7]}>
        <Box my={[4, 4, 5]}>
          <h1 style={{ display: "inline" }}>Lim Qian Joo</h1>
          <h2 style={{ display: "inline" }} className="cu">
            {" "}
            designs simple and purposeful digital user experiences.</h2>
          <br/>
          <br/>
          <h2 className="cu">At{" "}
            <a
              href="https://www.saltmine.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
                <u>Saltmine</u>
            </a>, she makes it easier for organisations to design and build spaces employees love.
          </h2>
        </Box>
        <Box my={[2, 2, 3]}>
          <span>Previously at</span>
          <h4>
            <a
              href="https://anywhr.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anywhr
            </a>
            {" & "}
            <a
              href="https://www.rewardnation.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              RewardNation
            </a>
          </h4>
        </Box>
        <Flex
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          mt={[5, 5, 7]}
        >
          <h4 style={{ opacity: 0.5 }}>
            <hr
              style={{
                display: "inline-block",
                width: "2rem",
                margin: "0.25rem",
              }}
            />
            Past Work
            <hr
              style={{
                display: "inline-block",
                width: "2rem",
                margin: "0.25rem",
              }}
            />
          </h4>
          <Box>
            <Flex flexWrap="wrap" mx={[-1, -2, -3]} textAlign="left">
              {works.map((work, index) => (
                <SelectedWorkLink
                  key={index}
                  title={work.title}
                  subtitle={work.subtitle}
                  meta={work.meta}
                  path={work.path}
                  cover={work.cover}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Layout>
)

export default IndexPage
