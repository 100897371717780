import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Box, Image } from "rebass"
import blankCover from "../images/blank-cover.jpg"

const Content = ({ path, meta, title, subtitle, cover }) => {
  return (
    <Box>
      {cover ? <Image src={cover} /> : <Image width="100%" src={blankCover} />}
      <Box py={[1, 2, 3]}>
        {path ? null : (
          <span>
            <span
              className="overline"
              style={{
                background: "#dad5fc",
                padding: "0.1rem",
              }}
            >
              Coming soon
            </span>
            &nbsp;
          </span>
        )}
        <span className="overline">{meta}</span>
        <h3 style={{ margin: "0.5rem 0" }}>{title}</h3>
        <span className="subtitle">{subtitle}</span>
      </Box>
    </Box>
  )
}

const SelectedWorkLink = ({ ...props }) => {
  const path = props.path
  return (
    <Box
      width={[1, 1, 1 / 2, 1 / 3]}
      px={[1, 2, 3]}
      py={[3, 3, 4]}
      style={path ? {} : { opacity: "0.6" }}
    >
      {path ? (
        <Link to={path}>{Content({ ...props })}</Link>
      ) : (
        Content({ ...props })
      )}
    </Box>
  )
}

Content.propTypes = {
  path: PropTypes.string,
  meta: PropTypes.node,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  cover: PropTypes.objectOf(Image),
}

SelectedWorkLink.propTypes = {
  path: PropTypes.string,
}

export default SelectedWorkLink
